import React, { Component } from 'react';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Subscribe } from 'unstated';
import { Breakpoint } from '@tiaanduplessis/react-resize';

import api from 'api';
import Header from '../common/header';
import Main from '../common/main';
import ListingCard from './ListingCard';
import MapboxMap from './MapboxMap';
import theme from 'config/theme';
import Filters from './Filters';
import SearchThisArea from './map/SearchThisArea';
import ListingCardMobile from './ListingCardMobile';
import { LEASE_TYPE } from './constants';
import { urlToQueryString } from './utilities';
import { EventType } from 'constants/events';
import listingContainer from 'containers/listing';
import { PageMeta } from 'constants/page_meta';
import authContainer from 'containers/auth';
import modalContainer from 'containers/modal';
import { Title } from 'lib/Title';
import { Button } from 'lib/Button';
import { AddAlertPopup } from './AddAlertPopup';
import { searchContainer } from 'containers/search';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_LARGE_PX } from 'constants/breakPoints';
import { ModalType } from 'constants/modalTypes';
import listingService from 'services/listingService';
import numberFormatService from 'services/numberFormatService';
import searchService from 'services/searchService';
import { analyticsService, PageName, EventName } from 'services/analyticsService';
import { ReactComponent as NotificationIcon } from 'assets/images/icons/notification.svg';
import LocationsRequiredImage from 'assets/images/empty-state.png';
import { SearchItemType } from 'models/search';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import InFeedAd from 'components/ads/InFeedAd';
import mapImage from 'assets/images/map.png';

import SortByFilter from './filters/SortByFilter';

var qs = require('qs');

const { fonts, colors, mobileHeaderHeight, zIndices } = theme;

const mobileHeaderHeightValue = 72;

const width =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const DEFAULT_FILTER_PARAMS = {};

const DEFAULT_MAP_COORDINATES = {
	lat: '-30.5595',
	lng: '22.9375',
};

const MAP_ZOOM = {
	default: 5,
	location: 10,
};

const TitleInfo = styled.div`
	font-size: 16px;
	margin-bottom: 8px;
`;

const AlertPopupContainer = styled.div`
	position: relative;
	margin-left: 16px;
`;

const MobileUpOnly = styled.div`
	display: none;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: block;
	}
`;

const SearchResultsHeader = styled.div`
	padding: 16px;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledNotificationIcon = styled(NotificationIcon)`
	height: 20px;
	margin-left: -8px;
	margin-right: 4px;
	fill: ${colors.darkBlue};
`;

const notifySuccess = message => {
	Swal.fire({
		title: message,
		type: 'success',
		showConfirmButton: false,
		toast: true,
		timer: 4000,
		position: 'top',
		customClass: {
			container: 'custom-container-toast-class',
		},
	});
};

const notifyWarning = message => {
	Swal.fire({
		title: message,
		type: 'warning',
		showConfirmButton: false,
		toast: true,
		timer: 5000,
		position: 'top',
		customClass: {
			container: 'custom-container-toast-class',
		},
	});
};

// apply rules to filter params
const getValidFilters = params => {
	if (params.type === LEASE_TYPE.EntirePlace) {
		// remove residence sub-type param
		params = {
			...params,
			sub_type: (params.sub_type || []).filter(x => x !== 'residence'),
		};
	}
	if (params.type === LEASE_TYPE.SingleRoom) {
		// remove bedrooms param
		params = {
			...params,
			bedrooms: undefined,
		};
	}
	const isInternational =
		'ZA' !==
		(searchContainer.state.selectedItems[0] && searchContainer.state.selectedItems[0].country_code);
	if (isInternational) {
		// remove verification params for international search
		params = {
			...params,
			nsfas_accredited: undefined,
			varsity_college_accredited: undefined,
		};
	}

	return params;
};

class DigsSearchV2 extends Component {
	constructor(props) {
		super(props);
		const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
		const { lat, lng } = queryParams || {};
		const mapCenter = [lng || DEFAULT_MAP_COORDINATES.lng, lat || DEFAULT_MAP_COORDINATES.lat];
		const isCustomMapLocation = !!lat && !!lng;
		this.state = {
			listings: [],
			listingsLoading: true,
			listingsNextPageSearchQuery: '',
			listingsTotalCount: 0,
			nearbyListings: [],
			nearbyListingsNextPageSearchQuery: '',
			params: DEFAULT_FILTER_PARAMS,
			features: [],
			showSearchHere: true,
			searchHereLoading: false,
			mapCenter: mapCenter,
			mapZoom: isCustomMapLocation ? MAP_ZOOM.location : MAP_ZOOM.default,
			selectedListingUuid: null,
			isSavingAlert: false,
			isAlertPopupVisible: false,
			viewPort: null,
			showMapCenterMarker: false,
			showMap: !featureFlagContainer.isEnabled(FeatureFlag.ClickToActivateSearchMap),
			map: undefined,
			priceFilterConfig: {},
			showCountrySearch: false,
			countrySearchLoading: false,
			showWarningTooltip: false,
		};
		this.scrollableContainerRef = React.createRef();
		this.addAlertTriggerRef = React.createRef();
		this.pageHeaderRef = React.createRef();
		this.mobileSearchHeaderRef = React.createRef();
		this.scrollableElementLatestScrollPosition = -1;
		this.pageHeaderTop = 0;
		this.mobileSearchHeaderTop = 72;
	}

	componentDidMount() {
		analyticsService.trackPage(PageName.Search);
		const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};
		this.filterOnQueryParams(queryParams);
		this.scrollableContainerRef.current.addEventListener('scroll', this.handleListingScroll);
		api.events.send({
			event_type: EventType.FindADigs,
		});
		authContainer.subscribe(this.onAuthStateChange);
		searchContainer.subscribe(this.onLocationsChange);

		if (!queryParams.locations && !queryParams.university) {
			let isMinOutOfRange = false;
			let isMaxOutOfRange = false;
			api.searchV2.getPriceFilter('ZA').then(({ data }) => {
				this.setState(
					state => {
						isMinOutOfRange = data.range_min > state.params.min_price;
						isMaxOutOfRange = data.range_max < state.params.max_price;

						return {
							priceFilterConfig: data,
							params: {
								...state.params,
								min_price: isMinOutOfRange ? undefined : state.params.min_price,
								max_price: isMaxOutOfRange ? undefined : state.params.max_price,
							},
							showWarningTooltip:
								!queryParams.locations && !queryParams.university && !queryParams.lat,
						};
					},
					() => {
						if (!isMinOutOfRange && !isMaxOutOfRange) {
							return;
						}

						this.searchListings();
					},
				);
			});
		}
	}

	componentWillUnmount() {
		authContainer.unsubscribe(this.onAuthStateChange);
		searchContainer.unsubscribe(this.onLocationsChange);
		this.scrollableContainerRef.current.removeEventListener('scroll', this.handleListingScroll);
	}

	onLocationsChange = () => {
		if (!searchContainer.state.selectedItems[0]) {
			return;
		}

		const firstItem = searchContainer.state.selectedItems[0];

		this.setState(
			state => ({
				...state,
				// TODO: Position map correctly based on all selected items
				mapCenter: [firstItem.longitude, firstItem.latitude],
				mapZoom: MAP_ZOOM.location,
				params: {
					...state.params,
					longitude: undefined,
					latitude: undefined,
					locations:
						firstItem.type === SearchItemType.Location
							? searchContainer.state.selectedItems.map(({ id }) => id)
							: undefined,
					university: firstItem.type === SearchItemType.University ? firstItem.id : undefined,
				},
				viewPort: searchService.getBoundingViewport(searchContainer.state.selectedItems),
				showMapCenterMarker: firstItem.type === SearchItemType.University,
				showWarningTooltip: false,
			}),
			() => {
				this.searchListings();

				// Assuming all areas are in the same country => using country of first search area
				const countryCode = firstItem.country_code;
				if (this.state.priceFilterConfig.country_code !== countryCode) {
					let isMinOutOfRange = false;
					let isMaxOutOfRange = false;
					api.searchV2
						.getPriceFilter((countryCode && countryCode.trim()) || 'ZA')
						.then(({ data }) => {
							this.setState(
								state => {
									isMinOutOfRange = data.range_min > state.params.min_price;
									isMaxOutOfRange = data.range_max < state.params.max_price;

									return {
										priceFilterConfig: data,
										params: {
											...state.params,
											min_price: isMinOutOfRange ? undefined : state.params.min_price,
											max_price: isMaxOutOfRange ? undefined : state.params.max_price,
										},
									};
								},
								() => {
									if (!isMinOutOfRange && !isMaxOutOfRange) {
										return;
									}

									this.searchListings();
								},
							);
						});
				}
			},
		);
	};

	onAuthStateChange = () => {
		if (!authContainer.state.token) {
			this.setState(state => ({
				...state,
				listings: state.listings.map(listing => ({
					...listing,
					favourite: false,
				})),
				nearbyListings: state.nearbyListings.map(listing => ({
					...listing,
					favourite: false,
				})),
			}));
			return;
		}

		api.favouritesV2
			.getFavourites()
			.then(res => res.data)
			.then(data => {
				const favouriteIdsObj = data.reduce(
					(acc, val) => ({
						...acc,
						[val.uuid]: true,
					}),
					{},
				);
				this.setState(state => ({
					...state,
					listings: state.listings.map(listing => ({
						...listing,
						favourite: favouriteIdsObj[listing.uuid],
					})),
					nearbyListings: state.nearbyListings.map(listing => ({
						...listing,
						favourite: favouriteIdsObj[listing.uuid],
					})),
				}));
			});
	};

	filterOnQueryParams = queryParams => {
		let searchParams = {};
		if (queryParams.locations) {
			searchParams = {
				...searchParams,
				locations:
					typeof queryParams.locations === 'string'
						? [+queryParams.locations]
						: queryParams.locations,
				university: undefined,
				latitude: undefined,
				longitude: undefined,
			};
		}
		if (queryParams.university) {
			searchParams = {
				...searchParams,
				university: queryParams.university,
				locations: undefined,
				latitude: undefined,
				longitude: undefined,
			};
		}
		if (queryParams.lat && queryParams.lng) {
			searchParams = {
				...searchParams,
				latitude: queryParams.lat,
				longitude: queryParams.lng,
				locations: undefined,
				university: undefined,
			};
		}
		if (queryParams.room_type) {
			searchParams = {
				...searchParams,
				room_type: queryParams.room_type,
			};
		}

		searchParams = {
			...searchParams,
			min_price: queryParams.min_price || this.state.params.min_price,
			max_price: queryParams.max_price || this.state.params.max_price,
			bedrooms: queryParams.bedrooms || this.state.params.bedrooms,
			private_bathroom: queryParams.private_bathroom || this.state.params.private_bathroom,
			sub_type: queryParams.sub_type || this.state.params.sub_type,
			nsfas_accredited: queryParams.nsfas_accredited || this.state.params.nsfas_accredited,
			aie_accredited: queryParams.aie_accredited || this.state.params.aie_accredited,
			varsity_college_accredited:
				queryParams.varsity_college_accredited || this.state.params.varsity_college_accredited,
			furnished: queryParams.furnished || this.state.params.furnished,
			tenants: queryParams.tenants || this.state.params.tenants,
			features:
				(queryParams.features && queryParams.features.map(feature => +feature)) ||
				this.state.params.features,
		};
		if (queryParams.type) {
			searchParams.type = queryParams.type;
		}
		this.setState(
			state => ({
				params: { ...state.params, ...searchParams },
			}),
			() => {
				const { lat, lng } = queryParams;
				if (lat && lng && lat !== this.state.mapCenter[1] && lng !== this.state.mapCenter[0]) {
					this.setState({
						mapCenter: [lng, lat],
						mapZoom: MAP_ZOOM.location,
					});
				}
				this.searchListings();
			},
		);
	};

	formatSearchParams = params => {
		const queryParams = { ...params };

		const nameMap = {
			latitude: 'lat',
			longitude: 'lng',
		};

		Object.keys(nameMap).forEach(key => {
			queryParams[nameMap[key]] = queryParams[key];
			delete queryParams[key];
		});

		Object.keys(queryParams).forEach(key => {
			if (typeof queryParams[key] === 'undefined') delete queryParams[key];
			else if (
				queryParams[key] === 0 ||
				queryParams[key] === '' ||
				queryParams[key] === 'undefined'
			)
				delete queryParams[key];
		});

		return Object.keys(queryParams)
			.map(key => {
				if (typeof queryParams[key] !== 'object') {
					return `${key}=${queryParams[key]}`;
				}
				if (queryParams[key] && queryParams[key].length) {
					return queryParams[key].map(item => `${key}=${item}`).join('&');
				}
				return '';
			})
			.join('&');
	};

	trackSearchResults = number_results => {
		const { params, features } = this.state;
		api.events.send({
			event_type: 'search_results',
			meta: {
				number_results,
				type: params.type,
				location: localStorage.getItem('search_label'),
				bedrooms: params.bedrooms,
				price_range: `${params.min_price} - ${params.max_price}`,
				property_type: !!params.sub_type ? String(params.sub_type) : 'None',
				bathroom_type: !!params.private_bathroom
					? 'private'
					: !!params.shared_bathroom
					? 'shared'
					: 'None',
				// eslint-disable-next-line
				features:
					!!params.features && params.features.length > 0
						? params.features
								// eslint-disable-next-line
								.map(id => {
									// eslint-disable-next-line
									for (let f of features) {
										if (f.id === id) {
											return f.name;
										}
									}
								})
								.join(', ')
						: 'None',
				coords: `${params.latitude}, ${params.longitude}`,
			},
		});
	};

	scrollToTop = () => {
		this.scrollableContainerRef.current.scrollTop = 0;
	};

	searchListings = () => {
		const { params } = this.state;
		this.setState({ listingsLoading: true });
		api.searchV2.getSearchFeatures().then(response => this.setState({ features: response.data }));
		return api.searchV2.searchDigs(getValidFilters(params)).then(response => {
			if (response.status !== 200) {
				return;
			}
			analyticsService.trackEvent(EventName.ListingSearch, {
				search_location: localStorage.getItem('search_label'),
				search_price_range_min: params.min_price,
				search_price_range_max: params.max_price,
				search_price_currency: this.state.priceFilterConfig?.symbol,
				search_tenants: params.tenants,
				property_type: '',
				listing_type: '',
				price: '',
				price_currency: '',
				city: '',
				suburb: '',
				country: '',
			});
			this.setState({
				showCountrySearch: false,
			});
			const { pathname } = this.props.history.location;
			const searchParams = this.formatSearchParams(params);
			window.history.pushState('state', 'title', `${pathname}?${searchParams}`);

			this.trackSearchResults(response.data.results.length);

			if (
				((params.latitude && params.longitude) || searchContainer.state.selectedItems[0]) &&
				response.data.results.length <= 5
			) {
				this.setState({
					listings: response.data.results,
					listingsNextPageSearchQuery: urlToQueryString(response.data.next),
					listingsTotalCount: response.data.count,
					nearbyListings: [],
					nearbyListingsNextPageSearchQuery: '',
				});
				const listingIdsObj = response.data.results.reduce(
					(obj, listing) => ({ ...obj, [listing.uuid]: true }),
					{},
				);
				return api.searchV2
					.searchDigs(
						getValidFilters({
							...params,
							locations: undefined,
							university: undefined,
							latitude: params.latitude || searchContainer.state.selectedItems[0].latitude,
							longitude: params.longitude || searchContainer.state.selectedItems[0].longitude,
							radius: 20,
						}),
					)
					.then(nearbyListingsResponse => {
						this.setState(
							{
								listingsLoading: false,
								nearbyListings: nearbyListingsResponse.data.results.filter(
									({ uuid }) => !listingIdsObj[uuid],
								),
								nearbyListingsNextPageSearchQuery: urlToQueryString(
									nearbyListingsResponse.data.next,
								),
								showCountrySearch:
									response.data.results.length === 0 &&
									nearbyListingsResponse.data.results.filter(({ uuid }) => !listingIdsObj[uuid])
										.length === 0,
							},
							this.scrollToTop,
						);
					});
			}

			this.setState(
				{
					listingsLoading: false,
					listings: response.data.results,
					listingsNextPageSearchQuery: urlToQueryString(response.data.next),
					listingsTotalCount: response.data.count,
					nearbyListings: [],
					nearbyListingsNextPageSearchQuery: '',
					showCountrySearch: response.data.results.length === 0,
				},
				this.scrollToTop,
			);
		});
	};

	hideMapCover = () => {
		this.setState({ showMap: true });
	};

	searchListingsByCoordinates = () => {
		if (!this.state.map) {
			return;
		}
		const { lat, lng } = this.state.map.getCenter();
		const zoom = this.state.map.getZoom();
		searchContainer.clear();
		this.setState(
			state => ({
				searchHereLoading: true,
				params: {
					...state.params,
					latitude: lat,
					longitude: lng,
					locations: undefined,
					university: undefined,
				},
				showMapCenterMarker: true,
				mapCenter: [lng, lat],
				mapZoom: zoom,
				viewPort: null,
			}),
			() => {
				this.searchListings().then(() => {
					this.setState({
						searchHereLoading: false,
					});
				});
			},
		);
	};

	getResultsNextPage = () => {
		const { nearbyListingsNextPageSearchQuery } = this.state;
		if (nearbyListingsNextPageSearchQuery) {
			this.setState({ listingsLoading: true });
			api.searchV2.getNextPage(nearbyListingsNextPageSearchQuery).then(response => {
				if (response.status !== 200) {
					return;
				}
				this.setState(state => ({
					listingsLoading: false,
					nearbyListings: [
						...state.nearbyListings,
						...(response.data.results || []).filter(
							l => !state.nearbyListings.some(n => n.uuid === l.uuid),
						),
					],
					nearbyListingsNextPageSearchQuery: urlToQueryString(response.data.next),
				}));
			});
			return;
		}

		const { listingsNextPageSearchQuery } = this.state;
		if (!listingsNextPageSearchQuery) {
			return;
		}
		this.setState({ listingsLoading: true });
		api.searchV2.getNextPage(listingsNextPageSearchQuery).then(response => {
			if (response.status === 200) {
				this.setState(state => ({
					listings: [
						...state.listings,
						...(response.data.results || []).filter(
							l => !state.listings.some(n => n.uuid === l.uuid),
						),
					],
					listingsLoading: false,
					listingsNextPageSearchQuery: urlToQueryString(response.data.next),
				}));
			}
		});
	};

	getPropertyTypeTitle = type => {
		if (type === LEASE_TYPE.EntirePlace) {
			return 'Entire places';
		}
		if (type === LEASE_TYPE.SingleRoom) {
			return 'Single rooms';
		}
		return 'All listings';
	};

	updateFilters = params => {
		if (!params) {
			// clear filter params
			const { pathname } = this.props.history.location;
			window.history.pushState('state', 'title', `${pathname}`);
			this.setState(state => ({
				params: {},
				listings: [],
				listingsNextPageSearchQuery: '',
				listingsTotalCount: 0,
				nearbyListings: [],
				nearbyListingsNextPageSearchQuery: '',
				showWarningTooltip: true,
			}));
			searchContainer.clear();

			return;
		}

		this.setState(
			state => ({
				params: { ...state.params, ...params },
			}),
			this.searchListings,
		);
	};

	updateHeaderScroll = () => {
		if (width > BREAKPOINT_MOBILE_PX) {
			return;
		}

		const prevScrollPosition = this.scrollableElementLatestScrollPosition;
		const newScrollPosition = this.scrollableContainerRef.current.scrollTop;
		this.scrollableElementLatestScrollPosition = newScrollPosition;

		if (
			prevScrollPosition >= 0 &&
			this.pageHeaderRef.current &&
			this.mobileSearchHeaderRef.current
		) {
			const delta = prevScrollPosition - newScrollPosition;
			const prevPageHeaderTop = this.pageHeaderTop;
			const prevSearchHeaderTop = this.mobileSearchHeaderTop;

			this.pageHeaderTop += delta;
			this.mobileSearchHeaderTop += delta;

			if (delta > 0) {
				// scroll header into view
				if (this.pageHeaderTop > 0) {
					this.pageHeaderTop = 0;
				}
				if (this.mobileSearchHeaderTop > mobileHeaderHeightValue) {
					this.mobileSearchHeaderTop = mobileHeaderHeightValue;
				}
			} else {
				// scroll header out of view
				if (this.pageHeaderTop < -mobileHeaderHeightValue) {
					this.pageHeaderTop = -mobileHeaderHeightValue;
				}
				if (this.mobileSearchHeaderTop < -this.mobileSearchHeaderRef.current.clientHeight) {
					this.mobileSearchHeaderTop = -this.mobileSearchHeaderRef.current.clientHeight;
				}
			}

			if (prevPageHeaderTop !== this.pageHeaderTop) {
				this.pageHeaderRef.current.style.top = `${this.pageHeaderTop}px`;
			}

			if (prevSearchHeaderTop !== this.mobileSearchHeaderTop) {
				this.mobileSearchHeaderRef.current.style.top = `${this.mobileSearchHeaderTop}px`;
			}
		}
	};

	handleListingCardClick = listing => {
		const link = listingService.getViewListingLink(listing);
		window.open(link, '_blank');
	};

	handleSearchCountryClick = locationId => {
		this.setState({
			countrySearchLoading: true,
		});
		api.searchV2.getLocation(locationId).then(({ data }) => {
			searchContainer.setSelectedItem(data);
			this.setState({
				countrySearchLoading: false,
			});
		});
	};

	handleListingScroll = () => {
		const element = this.scrollableContainerRef.current;

		this.updateHeaderScroll();

		const triggerMargin = 10;
		if (this.state.listingsLoading) {
			return;
		}
		if (element.scrollHeight - element.scrollTop - element.clientHeight < triggerMargin) {
			// reached the bottom of scroll => trigger next page load
			this.getResultsNextPage();
		}
	};

	handleAddAlertClick = () => {
		if (!authContainer.state.token) {
			modalContainer.set(ModalType.Login);
			return;
		}
		if (!this.state.params.university && !this.state.params.locations) {
			notifyWarning('First select a location or college');
			return;
		}
		this.setState(({ isAlertPopupVisible }) => ({ isAlertPopupVisible: !isAlertPopupVisible }));
	};

	handleSaveAlert = () => {
		if (this.state.isSavingAlert) {
			return;
		}
		this.setState({ isSavingAlert: true });
		api.alerts
			.create({
				search_filter: getValidFilters(this.state.params),
				active: true,
			})
			.then(response => {
				this.setState({
					isSavingAlert: false,
					isAlertPopupVisible: false,
				});
				if (response.status !== 200 && response.status !== 201) {
					return;
				}
				notifySuccess('Alert created successfully!');
			});
	};

	trackEmptySearch = () => {
		let params = {};
		Object.keys(this.state.params).forEach(key => {
			if (this.state.params[key] !== undefined) params[key] = this.state.params[key];
		});
		if (params.sub_type) params.sub_type = params.sub_type.join(', ');
		if (params.features) params.features = params.features.join(', ');

		api.events.send({
			event_type: EventType.SearchEmpty,
			meta: JSON.stringify({ ...params }),
		});
	};

	renderHeader(propertyTypeTitle, addAlertTriggerRef) {
		return (
			<>
				<ListingsHeader>
					<Subscribe to={[searchContainer]}>
						{searchContainer => (
							<Title noMargin mediumMobileText>
								{`Student Accommodation ${
									!!searchContainer.state.areaTitle ? `in ${searchContainer.state.areaTitle}` : ''
								}`}
							</Title>
						)}
					</Subscribe>
					<AlertPopupContainer>
						{featureFlagContainer.isEnabled(FeatureFlag.DigsAlert) && (
							<MobileUpOnly>
								<Button
									clickableRef={addAlertTriggerRef}
									height={48}
									isOutline
									onClick={this.handleAddAlertClick}
								>
									<StyledNotificationIcon />
									<span style={{ whiteSpace: 'nowrap' }}>Add alert</span>
								</Button>
							</MobileUpOnly>
						)}
						{this.state.isAlertPopupVisible && (
							<Subscribe to={[searchContainer]}>
								{searchContainer =>
									!!addAlertTriggerRef && (
										<AddAlertPopup
											title={`${propertyTypeTitle} ${
												!!searchContainer.state.areaTitle
													? `in ${searchContainer.state.areaTitle}`
													: ''
											}`}
											params={getValidFilters(this.state.params)}
											priceFilterConfig={this.state.priceFilterConfig}
											features={this.state.features}
											isLoading={this.state.isSavingAlert}
											onSave={this.handleSaveAlert}
											triggerElementRef={addAlertTriggerRef}
											onClose={() => this.setState({ isAlertPopupVisible: false })}
										/>
									)
								}
							</Subscribe>
						)}
					</AlertPopupContainer>
				</ListingsHeader>
				<FilterContainer>
					<Subscribe to={[searchContainer]}>
						{searchContainer => (
							<Filters
								filterParams={this.state.params}
								updateFilters={this.updateFilters}
								features={this.state.features}
								addAlertPillRef={addAlertTriggerRef}
								onAddAlertClick={this.handleAddAlertClick}
								priceFilterConfig={this.state.priceFilterConfig}
								showLocationWarningTooltip={this.state.showWarningTooltip}
								onWarningTooltipClose={() => this.setState({ showWarningTooltip: false })}
								isInternationalLocation={
									'ZA' !==
									(searchContainer.state.selectedItems[0] &&
										searchContainer.state.selectedItems[0].country_code)
								}
							/>
						)}
					</Subscribe>
				</FilterContainer>
			</>
		);
	}

	render() {
		const {
			listings,
			listingsNextPageSearchQuery,
			nearbyListings,
			nearbyListingsNextPageSearchQuery,
			listingsLoading,
			showSearchHere,
			searchHereLoading,
			mapCenter,
			mapZoom,
			selectedListingUuid,
			viewPort,
			showCountrySearch,
			showMap,
		} = this.state;

		const isLastResultsPage = !!listings.length && !listingsNextPageSearchQuery;
		const isLastNearbyResultsPage = !!nearbyListings.length && !nearbyListingsNextPageSearchQuery;
		const isZeroListings = !listings.length;
		const isMobile = width <= 768;
		const isNearbyListingsSectionVisible = !!nearbyListings.length;

		const isUni = localStorage.getItem('search_label_uni') === 'true';
		const search_label = localStorage.getItem('search_label');
		const meta = PageMeta.getSearch(search_label, isUni);

		const propertyTypeTitle = this.getPropertyTypeTitle(this.state.params.type);

		const hasLocationSelected =
			Boolean(this.state.params.locations) ||
			Boolean(this.state.params.university) ||
			Boolean(this.state.params.latitude || this.state.params.longitude);

		return (
			<StyledMain>
				<Helmet>
					<title>{meta.title}</title>
					<meta name="description" content={meta.desc} />
				</Helmet>
				<Header mobileSearchable findADigs elementRef={this.pageHeaderRef} />
				<Container>
					<LeftContainer>
						<Breakpoint minWidth={BREAKPOINT_MOBILE_PX}>
							{this.renderHeader(propertyTypeTitle, this.addAlertTriggerRef)}
						</Breakpoint>
						<Breakpoint maxWidth={BREAKPOINT_MOBILE_PX - 1}>
							<MobileHeaderContainer ref={this.mobileSearchHeaderRef}>
								{this.renderHeader(propertyTypeTitle, this.addAlertTriggerRef)}
							</MobileHeaderContainer>
						</Breakpoint>
						<ListingsContainer ref={this.scrollableContainerRef}>
							<Breakpoint maxWidth={BREAKPOINT_MOBILE_PX - 1}>
								{this.renderHeader(propertyTypeTitle, null)}
							</Breakpoint>
							{!hasLocationSelected ? (
								<MissingLocationContainer>
									<MissingLocationImage src={LocationsRequiredImage} alt="no location selected" />
									<MissingLocationHeader>
										<b>No location selected</b>
									</MissingLocationHeader>
									<MissingLocationText>
										Please add a location using the{' '}
										<span style={{ color: colors.darkBlue }}>filters</span> above to search for
										listings in the area.
									</MissingLocationText>
								</MissingLocationContainer>
							) : (
								<>
									{Boolean(listings.length) && (
										<SearchResultsHeader>
											<TitleInfo>
												{this.state.listingsLoading
													? 'loading listings...'
													: `${numberFormatService.format(this.state.listingsTotalCount)} listing${
															this.state.listingsTotalCount !== 1 ? 's' : ''
													  }`}
											</TitleInfo>
											<SortByFilter
												open={false}
												filterParams={this.state.params}
												updateFilters={this.updateFilters}
												openPopup={() => {}}
											/>
										</SearchResultsHeader>
									)}
									{listings.map((listing, i) => (
										<>
											{isMobile ? (
												<ListingCardMobile
													key={listing.uuid}
													{...listing}
													loading={listingsLoading}
													onClick={() => this.handleListingCardClick(listing)}
												/>
											) : (
												<ListingCard
													key={listing.uuid}
													{...listing}
													loading={listingsLoading}
													onClick={() => this.handleListingCardClick(listing)}
													onMouseOver={() => listingContainer.setHoverListing(listing.uuid)}
													onMouseOut={() => listingContainer.clearHoverListing()}
												/>
											)}
											{i > 0 && i % 5 === 0 && <InFeedAd />}
										</>
									))}
									{!listingsLoading && isLastResultsPage && !isNearbyListingsSectionVisible && (
										<EndOfSearchResults>No more listings</EndOfSearchResults>
									)}
									{isZeroListings && !listingsLoading && (
										<ListingsSectionHeader>
											<ListingsSectionTitle>No matching listings</ListingsSectionTitle>
											<p>Try adjusting your search by changing your filters.</p>
											{isNearbyListingsSectionVisible && (
												<p>Here are some nearby listings that we found:</p>
											)}
										</ListingsSectionHeader>
									)}
									{isNearbyListingsSectionVisible && (
										<>
											{!isZeroListings && (
												<ListingsSectionHeader>
													<ListingsSectionTitle>Nearby listings</ListingsSectionTitle>
													<p>Here are some nearby listings that we found:</p>
												</ListingsSectionHeader>
											)}
											{nearbyListings.map(listing =>
												isMobile ? (
													<ListingCardMobile
														key={listing.uuid}
														{...listing}
														loading={listingsLoading}
														onClick={() => this.handleListingCardClick(listing)}
													/>
												) : (
													<ListingCard
														key={listing.uuid}
														{...listing}
														loading={listingsLoading}
														onClick={() => this.handleListingCardClick(listing)}
														onMouseOver={() => listingContainer.setHoverListing(listing.uuid)}
														onMouseOut={() => listingContainer.clearHoverListing()}
													/>
												),
											)}
										</>
									)}
									{!listingsLoading && isLastNearbyResultsPage && (
										<EndOfSearchResults>No more listings</EndOfSearchResults>
									)}
									{showCountrySearch && (
										<Subscribe to={[searchContainer]}>
											{searchContainer =>
												searchContainer.state.selectedItems[0] &&
												searchContainer.state.selectedItems[0].parent_location_id ? (
													<CountrySearchButtonContainer>
														<br />
														<Button
															isLoading={this.state.countrySearchLoading}
															onClick={() =>
																this.handleSearchCountryClick(
																	searchContainer.state.selectedItems[0].parent_location_id,
																)
															}
														>
															Search this country
														</Button>
													</CountrySearchButtonContainer>
												) : (
													<></>
												)
											}
										</Subscribe>
									)}
								</>
							)}
						</ListingsContainer>
					</LeftContainer>
					<MapContainer>
						{!showMap && (
							<>
								<MapImage src={mapImage} />
								<MapOverlay onClick={this.hideMapCover} show={!showMap}>
									<MapOverlayButton whiteBackground onClick={this.hideMapCover}>
										Click to activate map
									</MapOverlayButton>
								</MapOverlay>
							</>
						)}
						{showMap && showSearchHere && (
							<SearchThisArea
								loading={searchHereLoading}
								onClick={this.searchListingsByCoordinates}
							/>
						)}
						{showMap && (
							<MapboxMap
								mapCenter={mapCenter}
								mapZoom={mapZoom}
								listings={[...listings, ...nearbyListings]}
								selectedListingUuid={selectedListingUuid}
								selectListing={uuid => this.setState({ selectedListingUuid: uuid })}
								viewPort={viewPort}
								onListingCardClick={this.handleListingCardClick}
								addCenterPin={this.state.showMapCenterMarker}
								onMapLoad={map => this.setState({ map })}
							/>
						)}
					</MapContainer>
				</Container>
			</StyledMain>
		);
	}
}

export default DigsSearchV2;

const StyledMain = styled(Main)`
	max-height: '100vh';

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding-top: 0;
	}
`;

const MapImage = styled.div`
	background-color: ${colors.grey20};
	background-size: cover;
	background-image: url(${mapImage});
	height: 100%;
	width: 100%;
`;

const Container = styled.div`
	height: calc(100vh - 85px);
	width: 100%;
	display: flex;
	-webkit-tap-highlight-color: transparent;
	font-family: ${fonts.Gilroy};

	@media (max-width: 768px) {
		height: calc(100vh);
	}
`;

const LeftContainer = styled.div`
	width: 50%;
	max-width: 750px;
	display: flex;
	flex-direction: column;

	@media (max-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		max-width: 100vw;
		width: 100vw;
	}
`;

const ListingsHeader = styled.div`
	padding: 16px;
	padding-top: 20px;
	padding-bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${colors.white};
`;

const FilterContainer = styled.div`
	width: 100%;
	padding: 8px 0;
	background-color: white;
	border-bottom: 1px solid ${colors.grey10};
	overflow-x: auto;
`;

const ListingsContainer = styled.div`
	background-color: ${colors.white};
	flex: 1;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding-top: 72px;
	}
`;

const MapContainer = styled.div`
	flex-grow: 1;
	position: relative;
	display: flex;
	justify-content: center;

	@media (max-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		display: none;
	}
`;

const EndOfSearchResults = styled.div`
	margin: 16px;
	color: ${colors.grey60};
	text-align: center;
`;

const ListingsSectionHeader = styled.div`
	margin-top: 16px;
	width: 100%;
	padding: 0 16px;
	text-align: left;
	line-height: 1.2em;
`;
const ListingsSectionTitle = styled.div`
	margin-bottom: 8px;
	width: 100%;
	font-weight: 700;
	font-size: 20px;
`;

const MobileHeaderContainer = styled.div`
	position: absolute;
	top: ${mobileHeaderHeight};
	left: 0;
	right: 0;
	z-index: 1;
`;

const CountrySearchButtonContainer = styled.div`
	margin: 16px;
`;

const MissingLocationContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
`;

const MissingLocationImage = styled.img`
	max-width: 360px;
	width: 100%;
`;

const MissingLocationHeader = styled.p`
	font-size: 16px;
	color: ${colors.darkBlue};
`;

const MissingLocationText = styled.p`
	font-size: 16px;
	color: ${colors.grey60};
	text-align: center;
	max-width: 300px;
`;

const MapOverlay = styled.div`
	z-index: ${zIndices.mapOverlay};
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	opacity: 0.9;
	align-items: center;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.4);
	${({ show }) => (show ? 'display: flex;' : `display: none;`)}
	justify-content: center;
	align-items: center;
`;

const MapOverlayButton = styled.div`
	border-radius: 8px;
	background-color: white;
	padding: 12px;
	top: 25px;
	z-index: ${zIndices.mapOverlay};
	margin: auto;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
`;
